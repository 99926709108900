import { Record as RRecord, Static } from "runtypes";

import { ExtractAtomicOperationFromDefinition } from "../../atomic-operations";
import { BlockConfig } from "../../blockCell";
import { DEFAULT_CHART_RESULT_VARIABLE } from "../../chart/chartConstants";
import { getChartReferencedDataFrames } from "../../chart/chartDataFrameUtils.js";
import { newSuggestibleChart } from "../../chart/createChartDefaults";
import {
  ChartColorMappings,
  ChartSelection,
  ChartSpec,
} from "../../chart/types";
import {
  CellReferencesParseError,
  CellReferencesV2,
  PythonCellReferencesV2,
  SqlCellReferencesV2,
  StoredCellReferencesV3,
  StoredJinjaSqlReferences,
  StoredSqlReferencesV3,
} from "../../code";
import { UTC } from "../../constants";
import {
  DateTimeString,
  TimezoneName,
  getDateTimeString,
} from "../../dateTypes";
import { SecondaryCalculations } from "../../dbt/generated/dbtSecondaryCalculations";
import {
  CalcsDefinition,
  ColumnAggregationsDefinition,
  ColumnDisplayFormat,
  ConditionalFormattingDefinition,
  DISPLAY_TABLE_DEFAULTS,
  SortDirection,
  TableFiltersDefinition,
} from "../../display-table";
import {
  CellOutputType,
  CellType,
  ChartDisplayType,
  CollapsibleCellLabelStyle,
  MetricCellComparisonFormat,
  MetricCellComparisonType,
} from "../../enums";
import { defaultExploreSpec } from "../../explore/exploreDefaults.js";
import { ExploreSpec, ExploreViewType } from "../../explore/types.js";
import {
  BASE_FILTER_GROUP,
  FilterCellAppMode,
  FilterGroup,
  FilterType,
} from "../../filter";
import {
  BlockCellId,
  CellGroupCellId,
  CellId,
  ChartCellId,
  CodeCellId,
  CollapsibleCellId,
  ComponentImportCellId,
  DataConnectionId,
  DataSourceDbtMetricId,
  DbtMetricCellId,
  DisplayTableCellId,
  DisplayTableColumnId,
  DisplayTableConfigId,
  ExploreCellId,
  FilterCellId,
  HexVersionId,
  MagicEventId,
  MapCellId,
  MarkdownCellId,
  MetricCellId,
  ParameterId,
  PivotCellId,
  SemanticDatasetName,
  SemanticProjectId,
  SqlCellId,
  StaticCellId,
  StoryElementId,
  TextCellId,
  VegaChartCellId,
  WritebackCellId,
} from "../../idTypeBrands";
import { Map, MapThemeHex } from "../../map/mapTypes";
import { MetricCellAggregate } from "../../metric/metricCellAggregate.js";
import { MetricDisplayFormat } from "../../metric/metricDisplayFormatTypes";
import {
  ParameterInputMetadata,
  ParameterInputType,
  ParameterOptions,
  ParameterOutputType,
} from "../../parameterType";
import { PivotTableConfig } from "../../pivot/pivotTypes";
import { convertRichTextFromPlainText } from "../../richText";
import { RichTextDocument } from "../../richTextTypes";
import { SemanticCap } from "../../semanticCaps.js";
import {
  DataSourceTableConfig,
  ExploreCellDataframe,
  NoCodeCellDataframe,
} from "../../sql/dataSourceTableConfig.js";
import {
  JsonSerializedParameterValueContents,
  ParameterName,
} from "../../typeBrands";
import { uuid } from "../../uuid.js";
import { VegaLiteSpec, VegaMetadata } from "../../vega-chart-cell/types";
import { newDefaultTopLevelLayerSpec } from "../../vega-chart-cell/vegaDefaultSpec";
import { createHexVersionAtomicOperationDefinition } from "../HexVersionAtomicOperationDefinition";

import { MAP_DEFAULT_HEIGHT } from "./map/mapConstants";

export type CreateOriginMetadata =
  | "ADD_CELL_BAR"
  | "MULTISELECT_BAR"
  | "CELL_ACTION_MENU";

export interface CodeCellPayload {
  type: (typeof CellType)["CODE"];
  id: CodeCellId;
  createdDate: DateTimeString;
  updatedDate: DateTimeString;
  revision: number;
  source: string;
  cellReferencesV2: PythonCellReferencesV2 | null;
  cellReferencesParseError: CellReferencesParseError | null;
}

export function createCodeCellPayload({
  cellReferencesParseError,
  cellReferencesV2,
  id = uuid() as CodeCellId,
  source,
}: {
  id?: CodeCellId;
  source?: string;
  cellReferencesV2?: PythonCellReferencesV2 | null;
  cellReferencesParseError?: CellReferencesParseError | null;
} = {}): CodeCellPayload {
  return {
    type: CellType.CODE,
    id,
    createdDate: getDateTimeString(new Date()),
    updatedDate: getDateTimeString(new Date()),
    revision: 0,
    source: source ?? "",
    cellReferencesV2: cellReferencesV2 ?? null,
    cellReferencesParseError: cellReferencesParseError ?? null,
  };
}

export interface MarkdownCellPayload {
  type: (typeof CellType)["MARKDOWN"];
  id: MarkdownCellId;
  createdDate: DateTimeString;
  updatedDate: DateTimeString;
  revision: number;
  source: string;
  cellReferencesV2: CellReferencesV2 | null;
  cellReferencesParseError: CellReferencesParseError | null;
}

export function createMarkdownCellPayload({
  cellReferencesParseError,
  cellReferencesV2,
  id = uuid() as MarkdownCellId,
  source,
}: {
  id?: MarkdownCellId;
  source?: string;
  cellReferencesV2?: CellReferencesV2 | null;
  cellReferencesParseError?: CellReferencesParseError | null;
} = {}): MarkdownCellPayload {
  return {
    type: CellType.MARKDOWN,
    id: id,
    createdDate: getDateTimeString(new Date()),
    updatedDate: getDateTimeString(new Date()),
    revision: 0,
    source: source ?? "",
    cellReferencesV2: cellReferencesV2 ?? null,
    cellReferencesParseError: cellReferencesParseError ?? null,
  };
}

export interface TextCellPayload {
  type: (typeof CellType)["TEXT"];
  id: TextCellId;
  createdDate: DateTimeString;
  updatedDate: DateTimeString;
  revision: number;
  richText: RichTextDocument;
  cellReferencesV2: CellReferencesV2 | null;
  cellReferencesParseError: CellReferencesParseError | null;
}

export function createTextCellPayload({
  cellReferencesParseError,
  cellReferencesV2,
  id = uuid() as TextCellId,
  richText,
}: {
  id?: TextCellId;
  richText?: RichTextDocument;
  cellReferencesV2?: CellReferencesV2 | null;
  cellReferencesParseError?: CellReferencesParseError | null;
} = {}): TextCellPayload {
  return {
    type: CellType.TEXT,
    id: id,
    createdDate: getDateTimeString(new Date()),
    updatedDate: getDateTimeString(new Date()),
    revision: 0,
    richText: richText ?? convertRichTextFromPlainText(""),
    cellReferencesV2: cellReferencesV2 ?? null,
    cellReferencesParseError: cellReferencesParseError ?? null,
  };
}

export interface CellGroupCellPayload {
  type: (typeof CellType)["CELL_GROUP"];
  id: CellGroupCellId;
  createdDate: DateTimeString;
  updatedDate: DateTimeString;
  revision: number;
  runOnAppLoad: boolean;
}

export interface DisplayTableConfigPayload {
  id: DisplayTableConfigId;
  revision: number;
  pageSize: number;
  height?: number;
  hideIcons: boolean;
  defaultColumnWidth?: number | null;
  hideIndex: boolean;
  sortByColumnDefault?: string;
  sortByIndexColumnDefault?: number;
  sortDirectionDefault: SortDirection;
  calcs?: CalcsDefinition;
  conditionalFormatting?: ConditionalFormattingDefinition;
  filters?: TableFiltersDefinition;
  columnProperties: Array<{
    originalName: DisplayTableColumnId;
    displayFormat?: ColumnDisplayFormat;
    renameTo?: string;
    size?: number;
    revision: number;
    wrapText?: boolean;
  }>;
  customColumnOrdering?: ReadonlyArray<DisplayTableColumnId>;
  pivotColumnOrdering?: ReadonlyArray<SortDirection | null>;
  pinnedColumns?: readonly string[];
  hiddenColumns?: readonly string[];
  pinIndexColumns: boolean;
  showAggregations?: boolean;
  columnAggregations?: ColumnAggregationsDefinition;
}

export interface CreateDisplayTableCellMandatoryFields {
  resultVariable: string;
}

export interface DisplayTableCellPayload {
  type: (typeof CellType)["DISPLAY_TABLE"];
  id: DisplayTableCellId;
  createdDate: DateTimeString;
  updatedDate: DateTimeString;
  revision: number;
  /**
   * @deprecated in favor of `dataframe
   */
  dataFrameVariableName?: string;
  dataframe: NoCodeCellDataframe | null;
  resultVariable: string;
  displayTableConfig: DisplayTableConfigPayload;
}

export function createDisplayTableCellPayload({
  dataframe = null,
  displayTableConfig,
  displayTableConfigId = uuid() as DisplayTableConfigId,
  id = uuid() as DisplayTableCellId,
  pageSize,
  resultVariable,
}: {
  id?: DisplayTableCellId;
  dataframe?: NoCodeCellDataframe | null;
  displayTableConfigId?: DisplayTableConfigId;
  displayTableConfig?: Partial<DisplayTableConfigPayload>;
  pageSize?: number;
  resultVariable?: string;
} = {}): DisplayTableCellPayload {
  return {
    type: CellType.DISPLAY_TABLE,
    id: id,
    createdDate: getDateTimeString(new Date()),
    updatedDate: getDateTimeString(new Date()),
    revision: 0,
    dataframe,
    resultVariable: resultVariable ?? "",
    displayTableConfig: {
      pageSize: pageSize ?? DISPLAY_TABLE_DEFAULTS.pageSize,
      hideIcons: false,
      hideIndex: false,
      defaultColumnWidth: null,
      sortDirectionDefault: SortDirection.ASC,
      columnProperties: [],
      pinIndexColumns: false,
      ...displayTableConfig,
      revision: 0,
      id: displayTableConfigId,
    },
  };
}

export interface CreateMetricCellMandatoryFields {
  valueResultVariable: string | undefined;
  comparisonResultVariable: string | undefined;
}

export interface MetricCellPayload {
  type: (typeof CellType)["METRIC"];
  id: MetricCellId;
  createdDate: DateTimeString;
  updatedDate: DateTimeString;
  revision: number;
  title: string | undefined;
  valueVariableName: string | undefined;
  displayFormat: MetricDisplayFormat | undefined;
  showComparison: boolean;
  comparisonType: MetricCellComparisonType | undefined;
  comparisonVariableName: string | undefined;
  comparisonLabel: string | undefined;
  comparisonFormat?: MetricCellComparisonFormat;
  valueRowIndex: number | undefined;
  valueColumn: string | undefined;
  valueAggregate: MetricCellAggregate | undefined;
  comparisonColumn: string | undefined;
  comparisonRowIndex: number | undefined;
  comparisonAggregate: MetricCellAggregate | undefined;
  valueResultVariable: string | undefined;
  comparisonResultVariable: string | undefined;
  outputResult: boolean | undefined;
}

export function createMetricCellPayload({
  comparisonAggregate,
  comparisonColumn,
  comparisonFormat,
  comparisonLabel,
  comparisonResultVariable,
  comparisonRowIndex,
  comparisonType,
  comparisonVariableName,
  displayFormat,
  id = uuid() as MetricCellId,
  outputResult,
  showComparison,
  title,
  valueAggregate,
  valueColumn,
  valueResultVariable,
  valueRowIndex,
  valueVariableName,
}: {
  id?: MetricCellId;
  title?: string;
  valueVariableName?: string;
  displayFormat?: MetricDisplayFormat;
  showComparison?: boolean;
  comparisonType?: MetricCellComparisonType;
  comparisonVariableName?: string;
  comparisonFormat?: MetricCellComparisonFormat;
  comparisonLabel?: string;
  valueRowIndex?: number;
  valueColumn?: string;
  comparisonColumn?: string;
  comparisonRowIndex?: number;
  valueAggregate?: MetricCellAggregate;
  comparisonAggregate?: MetricCellAggregate;
  valueResultVariable?: string;
  comparisonResultVariable?: string;
  outputResult?: boolean;
}): MetricCellPayload {
  return {
    type: CellType.METRIC,
    id,
    createdDate: getDateTimeString(new Date()),
    updatedDate: getDateTimeString(new Date()),
    revision: 0,
    title,
    comparisonFormat,
    comparisonLabel,
    comparisonType: comparisonType ?? MetricCellComparisonType.VALUE,
    comparisonVariableName,
    displayFormat,
    showComparison: showComparison ?? false,
    valueVariableName,
    valueRowIndex,
    valueColumn,
    comparisonColumn,
    comparisonRowIndex,
    valueAggregate: valueAggregate,
    comparisonAggregate,
    valueResultVariable,
    comparisonResultVariable,
    outputResult,
  };
}

export interface InputCellPayload {
  type: (typeof CellType)["INPUT"];
  id: ParameterId;
  createdDate: DateTimeString;
  updatedDate: DateTimeString;
  revision: number;
  name: ParameterName;
  outputType: ParameterOutputType;
  inputType: ParameterInputType;
  options?: ParameterOptions | null;
  defaultValueString?: JsonSerializedParameterValueContents;
  required: boolean;
}

export function createInputCellPayload({
  defaultValueString,
  id = uuid() as ParameterId,
  inputType,
  name,
  options,
  outputType,
}: {
  id?: ParameterId;
  name: ParameterName;
  inputType?: ParameterInputType;
  outputType?: ParameterOutputType;
  defaultValueString?: JsonSerializedParameterValueContents;
  options?: ParameterOptions;
}): InputCellPayload {
  const nonNullInputType = inputType ?? ParameterInputType.TEXT_INPUT;
  const nonNullOutputType =
    outputType ?? ParameterInputMetadata[nonNullInputType].allowedDataTypes[0];
  if (!nonNullOutputType) {
    throw new Error(`No valid output types for input type ${nonNullInputType}`);
  }

  return {
    type: CellType.INPUT,
    id: id,
    createdDate: getDateTimeString(new Date()),
    updatedDate: getDateTimeString(new Date()),
    revision: 0,
    name,
    inputType: nonNullInputType,
    outputType: nonNullOutputType,
    defaultValueString,
    required: false,
    options,
  };
}

export interface CreateCellHelpers {
  getNewSqlCellFields: () => CreateSqlCellMandatoryFields;
  getNewDbtMetricCellFields: () => CreateDbtMetricCellMandatoryFields;
  getNewPivotCellFields: () => CreatePivotCellMandatoryFields;
  getUniqueInputCellName: () => ParameterName;
  getNewFilterCellFields: () => CreateFilterCellMandatoryFields;
  getNewChartCellFields: () => CreateChartCellMandatoryFields;
  getNewDisplayTableCellFields: () => CreateDisplayTableCellMandatoryFields;
  getNewMetricCellFields: (
    outputResult: boolean,
  ) => CreateMetricCellMandatoryFields;
}

export interface CreateSqlCellMandatoryFields {
  // a new unique result variable that shouldn't conflict
  resultVariable: string;
  // the last used connection id
  connectionId?: DataConnectionId;
  castDecimals: boolean;
}

export interface SqlCellPayload {
  type: (typeof CellType)["SQL"];
  id: SqlCellId;
  createdDate: DateTimeString;
  updatedDate: DateTimeString;
  revision: number;
  source: string;
  resultVariable: string;
  useRichDisplay: boolean;
  castDecimals: boolean;
  useNativeDates: boolean;
  loadIntoDataFrame: boolean;
  sqlCellOutputType: CellOutputType;
  allowDuplicateColumns: boolean;
  /**
   * perms on this are manually checked server side
   */
  connectionId: DataConnectionId | null;
  dataFrameCell?: boolean;
  sqlDisplayTableConfig: DisplayTableConfigPayload | null;
  cellReferencesV2: SqlCellReferencesV2 | null;
  cellReferencesParseError: CellReferencesParseError | null;
  sqlCellReferencesV3: StoredSqlReferencesV3 | null;
  jinjaCellReferencesV3: StoredCellReferencesV3 | null;
  jinjaSqlReferences: StoredJinjaSqlReferences | null;
}

export function createSqlCellPayload({
  castDecimals,
  cellReferencesParseError,
  cellReferencesV2,
  connectionId,
  dataFrameCell,
  id = uuid() as SqlCellId,
  jinjaCellReferencesV3,
  jinjaSqlReferences,
  loadIntoDataFrame,
  resultVariable,
  source,
  sqlCellReferencesV3,
  sqlDisplayTableConfig,
  useNativeDates,
  useRichDisplay,
}: {
  id?: SqlCellId;
  source?: string;
  resultVariable: string;
  useRichDisplay?: boolean;
  sqlDisplayTableConfig?: DisplayTableConfigPayload | null;
  castDecimals: boolean;
  useNativeDates?: boolean;
  loadIntoDataFrame?: boolean;
  connectionId?: DataConnectionId;
  dataFrameCell?: boolean;
  cellReferencesV2?: SqlCellReferencesV2 | null;
  cellReferencesParseError?: CellReferencesParseError | null;
  sqlCellReferencesV3?: StoredSqlReferencesV3 | null;
  jinjaCellReferencesV3?: StoredCellReferencesV3 | null;
  jinjaSqlReferences?: StoredJinjaSqlReferences | null;
}): SqlCellPayload {
  return {
    type: CellType.SQL,
    id,
    createdDate: getDateTimeString(new Date()),
    updatedDate: getDateTimeString(new Date()),
    revision: 0,
    source: source ?? "",
    resultVariable,
    castDecimals,
    useNativeDates: useNativeDates ?? true,
    useRichDisplay: useRichDisplay ?? false,
    connectionId: connectionId ?? null,
    dataFrameCell: dataFrameCell ?? false,
    sqlDisplayTableConfig: sqlDisplayTableConfig ?? null,
    loadIntoDataFrame: loadIntoDataFrame ?? true,
    cellReferencesV2: cellReferencesV2 ?? null,
    cellReferencesParseError: cellReferencesParseError ?? null,
    sqlCellReferencesV3: sqlCellReferencesV3 ?? null,
    jinjaCellReferencesV3: jinjaCellReferencesV3 ?? null,
    jinjaSqlReferences: jinjaSqlReferences ?? null,
    sqlCellOutputType: CellOutputType.PANDAS,
    allowDuplicateColumns: false,
  };
}

export interface VegaChartCellPayload {
  type: (typeof CellType)["VEGA_CHART"];
  id: VegaChartCellId;
  createdDate: DateTimeString;
  updatedDate: DateTimeString;
  revision: number;
  vegaSpec: VegaLiteSpec | null;
  metadata: VegaMetadata | null;
  height: number | null;
  selectedLayerIndex: number;
  defaultInputTimezone: TimezoneName | null;
}

export function createVegaChartCellPayload({
  defaultInputTimezone = UTC,
  id = uuid() as VegaChartCellId,
  selectedDataFrameVariableName,
}: {
  id?: VegaChartCellId;
  defaultInputTimezone?: TimezoneName | null;
  /**
   * If provided, will intialize the cell with a vega-lite spec and metadata referencing this dataframe
   */
  selectedDataFrameVariableName?: string;
} = {}): VegaChartCellPayload {
  return {
    type: CellType.VEGA_CHART,
    id,
    createdDate: getDateTimeString(new Date()),
    updatedDate: getDateTimeString(new Date()),
    revision: 0,
    vegaSpec:
      selectedDataFrameVariableName == null
        ? null
        : newDefaultTopLevelLayerSpec(),
    metadata:
      selectedDataFrameVariableName == null
        ? null
        : {
            byLayer: [
              {
                selectedDataFrameVariableName,
              },
            ],
          },
    height: null,
    selectedLayerIndex: 0,
    defaultInputTimezone,
  };
}

export interface MapCellPayload {
  type: (typeof CellType)["MAP"];
  id: MapCellId;
  createdDate: DateTimeString;
  updatedDate: DateTimeString;
  revision: number;
  map: Map;
  height: number | null;
}

export function createMapCellPayload({
  id = uuid() as MapCellId,
}: {
  id?: MapCellId;
} = {}): MapCellPayload {
  return {
    type: CellType.MAP,
    id,
    createdDate: getDateTimeString(new Date()),
    updatedDate: getDateTimeString(new Date()),
    revision: 0,
    map: {
      layers: [],
      theme: MapThemeHex.value,
    },
    height: MAP_DEFAULT_HEIGHT,
  };
}

export interface CreateChartCellMandatoryFields {
  resultVariable: string;
}

export interface ChartCellPayload {
  type: (typeof CellType)["CHART"];
  id: ChartCellId;
  createdDate: DateTimeString;
  updatedDate: DateTimeString;
  revision: number;
  chartSpec: ChartSpec;
  chartSelection: ChartSelection | null;
  resultVariable: string;
  height: number | null;
  chartDisplayTableConfig: DisplayTableConfigPayload | null;
  colorMappings: ChartColorMappings;
  outputResult: boolean;
  displayType: ChartDisplayType;
}

export function createChartCellPayload({
  chartSpec,
  createDisplayTableConfig,
  displayTableConfig,
  id = uuid() as ChartCellId,
  outputResult,
  resultVariable,
}: {
  id?: ChartCellId;
  chartSpec?: ChartSpec;
  resultVariable?: string;
  createDisplayTableConfig?: boolean;
  outputResult?: boolean;
  displayTableConfig?: Partial<DisplayTableConfigPayload>;
} = {}): ChartCellPayload {
  const dataframes =
    chartSpec != null ? getChartReferencedDataFrames(chartSpec) : [];
  const isDataSourceTableConfig = dataframes.some(DataSourceTableConfig.guard);
  return {
    type: CellType.CHART,
    id,
    createdDate: getDateTimeString(new Date()),
    updatedDate: getDateTimeString(new Date()),
    revision: 0,
    chartSpec: chartSpec ?? newSuggestibleChart(),
    chartSelection: null,
    resultVariable: resultVariable ?? DEFAULT_CHART_RESULT_VARIABLE,
    height: null,
    displayType: ChartDisplayType.CHART,
    chartDisplayTableConfig:
      displayTableConfig != null ||
      (createDisplayTableConfig ?? isDataSourceTableConfig)
        ? {
            pageSize: DISPLAY_TABLE_DEFAULTS.pageSize,
            hideIcons: false,
            hideIndex: false,
            defaultColumnWidth: null,
            sortDirectionDefault: SortDirection.ASC,
            columnProperties: [],
            pinIndexColumns: false,
            ...displayTableConfig,
            id: DisplayTableConfigId.check(uuid()),
            revision: 0,
          }
        : null,
    colorMappings: {},
    outputResult: outputResult ?? isDataSourceTableConfig,
  };
}

export interface ExploreCellPayload {
  type: (typeof CellType)["EXPLORE"];
  id: ExploreCellId;
  dataframe: ExploreCellDataframe | null;
  createdDate: DateTimeString;
  updatedDate: DateTimeString;
  revision: number;
  colorMappings: ChartColorMappings;
  displayTableConfig: DisplayTableConfigPayload;
  spec: ExploreSpec;
  semanticProjectId: SemanticProjectId | null;
  resultVariables: readonly string[];
}

export const ExploreCellSemanticInputPayload = RRecord({
  semanticDatasetName: SemanticDatasetName,
  semanticProjectId: SemanticProjectId,
});
type ExploreCellSemanticInputPayload = Static<
  typeof ExploreCellSemanticInputPayload
>;

export type ExploreCellInput =
  | NoCodeCellDataframe
  | ExploreCellSemanticInputPayload;

export function createExploreCellPayload({
  colorMappings,
  displayTableConfig,
  id = uuid() as ExploreCellId,
  input,
  spec: initialSpec,
  viewType,
}: {
  id?: ExploreCellId;
  input?: ExploreCellInput;
  viewType?: ExploreViewType;
  displayTableConfig?: DisplayTableConfigPayload;
  spec?: ExploreSpec;
  colorMappings?: ChartColorMappings;
} = {}): ExploreCellPayload {
  const spec = { ...(initialSpec ?? defaultExploreSpec()) };
  spec.viewType = viewType ?? spec.viewType;

  let dataframe: ExploreCellDataframe | null = null;
  let semanticProjectId: SemanticProjectId | null = null;
  if (NoCodeCellDataframe.guard(input)) {
    dataframe = input;
  } else if (input) {
    semanticProjectId = input.semanticProjectId;
    dataframe = {
      semanticDatasetName: input.semanticDatasetName,
      type: "SemanticDataset",
    };
  }

  return {
    type: CellType.EXPLORE,
    id,
    dataframe: dataframe,
    createdDate: getDateTimeString(new Date()),
    updatedDate: getDateTimeString(new Date()),
    revision: 0,
    displayTableConfig: displayTableConfig ?? {
      id: DisplayTableConfigId.check(uuid()),
      revision: 0,
      pageSize: DISPLAY_TABLE_DEFAULTS.pageSize,
      hideIcons: false,
      hideIndex: false,
      defaultColumnWidth: null,
      sortDirectionDefault: SortDirection.ASC,
      columnProperties: [],
      pinIndexColumns: false,
    },
    colorMappings: colorMappings ?? {},
    spec,
    semanticProjectId,
    resultVariables: [],
  };
}

export interface WritebackCellPayload {
  type: (typeof CellType)["WRITEBACK"];
  id: WritebackCellId;
  createdDate: DateTimeString;
  updatedDate: DateTimeString;
  revision: number;
  connectionId: DataConnectionId | null;
  connectionName: string | null;
  databaseName: string | null;
  schemaName: string | null;
  tableName: string | null;
  dataframeName: string | null;
  runInLogicView: boolean;
  runOnScheduledRun: boolean;
  runInApp: boolean;
  overwrite: boolean;
  dynamicTableName: boolean;
}

export function createWritebackCellPayload({
  dataframeName = null,
  id = uuid() as WritebackCellId,
}: {
  id?: WritebackCellId;
  dataframeName?: string | null;
} = {}): WritebackCellPayload {
  return {
    type: CellType.WRITEBACK,
    id,
    createdDate: getDateTimeString(new Date()),
    updatedDate: getDateTimeString(new Date()),
    revision: 0,
    connectionId: null,
    connectionName: null,
    databaseName: null,
    schemaName: null,
    tableName: null,
    dataframeName,
    runInLogicView: false,
    runInApp: false,
    runOnScheduledRun: false,
    overwrite: true,
    dynamicTableName: false,
  };
}

export interface CreateDbtMetricCellMandatoryFields {
  // a new unique result variable that shouldn't conflict
  resultVariable: string;
  // the last used connection id
  connectionId?: DataConnectionId;
  castDecimals: boolean;
}
export interface DbtMetricCellPayload {
  type: (typeof CellType)["DBT_METRIC"];
  id: DbtMetricCellId;
  createdDate: DateTimeString;
  updatedDate: DateTimeString;
  revision: number;
  resultVariable: string;
  useRichDisplay: boolean;
  castDecimals: boolean;
  useNativeDates: boolean;
  secondaryCalculations: SecondaryCalculations[];
  /**
   * perms on this are manually checked server side
   */
  connectionId: DataConnectionId | null;
  dbtMetricDisplayTableConfig: DisplayTableConfigPayload | null;
  metricIds: DataSourceDbtMetricId[];
  selectedTimegrain: string | null;
  selectedDimensions: string[] | null;
  startDate: DateTimeString | null;
  endDate: DateTimeString | null;
}

export function createDbtMetricCellPayload({
  castDecimals,
  connectionId,
  displayTableConfigId = uuid() as DisplayTableConfigId,
  endDate,
  id = uuid() as DbtMetricCellId,
  metricIds = [],
  resultVariable,
  secondaryCalculations,
  selectedDimensions,
  selectedTimegrain,
  startDate,
  useRichDisplay,
}: {
  id?: DbtMetricCellId;
  displayTableConfigId?: DisplayTableConfigId;
  resultVariable: string;
  useRichDisplay?: boolean;
  castDecimals: boolean;
  connectionId?: DataConnectionId;
  metricIds?: DataSourceDbtMetricId[];
  selectedTimegrain?: string;
  selectedDimensions?: string[];
  secondaryCalculations?: SecondaryCalculations[];
  startDate?: DateTimeString | null;
  endDate?: DateTimeString | null;
}): DbtMetricCellPayload {
  return {
    type: CellType.DBT_METRIC,
    id,
    createdDate: getDateTimeString(new Date()),
    updatedDate: getDateTimeString(new Date()),
    revision: 0,
    resultVariable,
    castDecimals,
    useNativeDates: true,
    secondaryCalculations: secondaryCalculations ?? [],
    useRichDisplay: useRichDisplay ?? false,
    connectionId: connectionId ?? null,
    metricIds,
    dbtMetricDisplayTableConfig: {
      id: displayTableConfigId,
      revision: 0,
      pageSize: DISPLAY_TABLE_DEFAULTS.pageSize,
      hideIcons: false,
      hideIndex: false,
      defaultColumnWidth: null,
      sortDirectionDefault: DISPLAY_TABLE_DEFAULTS.sortDirectionDefault,
      columnProperties: [],
      pinIndexColumns: false,
    },
    selectedTimegrain: selectedTimegrain ?? null,
    selectedDimensions: selectedDimensions ?? null,
    startDate: startDate ?? null,
    endDate: endDate ?? null,
  };
}

export interface CreatePivotCellMandatoryFields {
  resultVariable: string;
  castDecimals: boolean;
}

export interface PivotCellPayload {
  type: (typeof CellType)["PIVOT"];
  id: PivotCellId;
  createdDate: DateTimeString;
  updatedDate: DateTimeString;
  revision: number;
  dataframe: NoCodeCellDataframe | null;
  resultVariable: string;
  pivotConfig: PivotTableConfig;
  displayTableConfig: DisplayTableConfigPayload;
  castDecimals: boolean;
}

export function createPivotCellPayload({
  castDecimals,
  dataframe = null,
  displayTableConfig,
  displayTableConfigId = uuid(),
  id = uuid() as PivotCellId,
  pivotConfig,
  resultVariable,
}: {
  id?: PivotCellId;
  dataframe?: NoCodeCellDataframe | null;
  resultVariable: string;
  displayTableConfigId?: string;
  displayTableConfig?: Partial<DisplayTableConfigPayload>;
  castDecimals: boolean;
  pivotConfig?: PivotTableConfig;
}): PivotCellPayload {
  return {
    type: CellType.PIVOT,
    id,
    createdDate: getDateTimeString(new Date()),
    updatedDate: getDateTimeString(new Date()),
    revision: 0,
    dataframe,
    resultVariable,
    castDecimals,
    pivotConfig: pivotConfig ?? {
      rows: [],
      columns: [],
      values: [],
    },
    displayTableConfig: {
      pageSize: DISPLAY_TABLE_DEFAULTS.pageSize,
      hideIcons: false,
      hideIndex: false,
      defaultColumnWidth: null,
      sortDirectionDefault: SortDirection.ASC,
      columnProperties: [],
      pinIndexColumns: true,
      ...displayTableConfig,
      id: displayTableConfigId as DisplayTableConfigId,
      revision: 0,
    },
  };
}

export interface CreateFilterCellMandatoryFields {
  resultVariable: string;
  castDecimals: boolean;
}

export interface FilterCellPayload {
  type: (typeof CellType)["FILTER"];
  id: FilterCellId;
  createdDate: DateTimeString;
  updatedDate: DateTimeString;
  revision: number;
  dataframe: NoCodeCellDataframe | null;
  filters: FilterGroup | null;
  resultVariable: string;
  filterType: FilterType;
  appMode: FilterCellAppMode;
  castDecimals: boolean;
  useQueryMode: boolean;
  useNativeDates: boolean;
  cellOutputType: CellOutputType | null;
}

export function createFilterCellPayload({
  castDecimals,
  cellOutputType = CellOutputType.PANDAS,
  dataframe = null,
  emptyFilterGroup = false,
  id = uuid() as FilterCellId,
  resultVariable,
  useQueryMode,
}: {
  id?: FilterCellId;
  dataframe?: NoCodeCellDataframe | null;
  resultVariable: string;
  castDecimals: boolean;
  cellOutputType?: CellOutputType;
  useQueryMode?: boolean;
  emptyFilterGroup?: boolean;
}): FilterCellPayload {
  return {
    type: CellType.FILTER,
    id,
    createdDate: getDateTimeString(new Date()),
    updatedDate: getDateTimeString(new Date()),
    revision: 0,
    dataframe,
    filters: emptyFilterGroup ? null : BASE_FILTER_GROUP,
    resultVariable,
    filterType: FilterType.KEEP,
    appMode: FilterCellAppMode.FULL_ACCESS,
    castDecimals,
    cellOutputType,
    useQueryMode: useQueryMode ?? false,
    useNativeDates: true,
  };
}

export interface ComponentImportCellPayload {
  type: (typeof CellType)["COMPONENT_IMPORT"];
  id: ComponentImportCellId;
  createdDate: DateTimeString;
  updatedDate: DateTimeString;
  revision: number;
  componentVersionId: HexVersionId | null;
  /** @deprecated @see hexVersionIdToCopyFilesFrom */
  cellIdMapping?: Record<CellId, CellId>;
  hexVersionIdToCopyFilesFrom?: HexVersionId;
}

export function createComponentImportCellPayload({
  cellIdMapping,
  componentVersionId,
  hexVersionIdToCopyFilesFrom,
  id = uuid() as ComponentImportCellId,
}: {
  id?: ComponentImportCellId;
  componentVersionId?: HexVersionId;
  cellIdMapping?: Record<CellId, CellId>;
  hexVersionIdToCopyFilesFrom?: HexVersionId;
}): ComponentImportCellPayload {
  return {
    type: CellType.COMPONENT_IMPORT,
    id,
    createdDate: getDateTimeString(new Date()),
    updatedDate: getDateTimeString(new Date()),
    revision: 0,
    componentVersionId: componentVersionId ?? null,
    cellIdMapping,
    hexVersionIdToCopyFilesFrom,
  };
}

export interface CollapsibleCellPayload {
  type: (typeof CellType)["COLLAPSIBLE"];
  id: CollapsibleCellId;
  createdDate: DateTimeString;
  updatedDate: DateTimeString;
  revision: number;
  labelStyle: CollapsibleCellLabelStyle;
}

export function createCollapsibleCellPayload({
  id = uuid() as CollapsibleCellId,
  labelStyle = CollapsibleCellLabelStyle.AUTO,
}: {
  id?: CollapsibleCellId;
  labelStyle?: CollapsibleCellLabelStyle;
}): CollapsibleCellPayload {
  return {
    type: CellType.COLLAPSIBLE,
    id,
    createdDate: getDateTimeString(new Date()),
    updatedDate: getDateTimeString(new Date()),
    revision: 0,
    labelStyle,
  };
}

export interface BlockCellPayload {
  type: (typeof CellType)["BLOCK"];
  id: BlockCellId;
  createdDate: DateTimeString;
  updatedDate: DateTimeString;
  revision: number;
  blockConfig: BlockConfig | null;
}

export function createBlockCellPayload({
  blockConfig,
  id = uuid() as BlockCellId,
}: {
  id?: BlockCellId;
  blockConfig: BlockConfig | null;
}): BlockCellPayload {
  return {
    type: CellType.BLOCK,
    id,
    createdDate: getDateTimeString(new Date()),
    updatedDate: getDateTimeString(new Date()),
    revision: 0,
    blockConfig,
  };
}

export type CellContentsPayload =
  | CodeCellPayload
  | MarkdownCellPayload
  | TextCellPayload
  | DisplayTableCellPayload
  | InputCellPayload
  | SqlCellPayload
  | VegaChartCellPayload
  | CellGroupCellPayload
  | MetricCellPayload
  | MapCellPayload
  | WritebackCellPayload
  | DbtMetricCellPayload
  | PivotCellPayload
  | FilterCellPayload
  | ComponentImportCellPayload
  | CollapsibleCellPayload
  | ChartCellPayload
  | BlockCellPayload
  | ExploreCellPayload;

export interface StoryElementPayload {
  id: StoryElementId;
  visible: boolean;
  createdDate: DateTimeString;
  updatedDate: DateTimeString;
  showLabel: boolean;
  revision: number;
}

export function createStoryElementPayload({
  id,
  visible,
}: {
  id: StoryElementId;
  visible?: boolean;
}): StoryElementPayload {
  return {
    id,
    createdDate: getDateTimeString(new Date()),
    updatedDate: getDateTimeString(new Date()),
    revision: 0,
    visible: visible ?? false,
    showLabel: true,
  };
}

const CREATE_CELL_TYPE = "CREATE_CELL" as const;
export const CREATE_CELL = createHexVersionAtomicOperationDefinition({
  type: CREATE_CELL_TYPE,
  readAuth: {
    kind: "hasSemanticCap",
    cap: SemanticCap.VIEW_PROJECT_CONTENTS_FOR_LOGIC,
  },
  // we check perms on static cell id server side manually
  writeAuth: {
    kind: "and",
    and: [
      {
        kind: "hasSemanticCap",
        cap: SemanticCap.EDIT_PROJECT_CONTENTS,
      },
      {
        kind: "idArgDoesNotExist",
        idArg: "cellId",
        idType: "Cell",
      },
    ],
  },
  logSafe: [
    "cellId",
    "staticCellId",
    "insertAfter",
    "insertBefore",
    "insertAt",
    "runAfterInsertion",
    "forceDisableRunOnInsertion",
    "parentComponentImportCellId",
    "componentImportCellId",
    "parentBlockCellId",
    "latestMagicEventId",
  ],
  conflictId: (op) => `${CREATE_CELL_TYPE}-${op.payload.cellId}`,
  excludeFromHistory: (op) =>
    op.payload.contents.type === "BLOCK" ||
    op.payload.parentBlockCellId != null ||
    !!op.payload.excludeFromHistory,
  excludeExtraOpsFromHistory: (op) =>
    op.payload.contents.type === "COMPONENT_IMPORT",
  create: (args: {
    cellId: CellId;
    staticCellId: StaticCellId;
    insertAfter: string | undefined;
    insertBefore: string | undefined;
    insertAt: string;
    contents: CellContentsPayload;
    storyElement: StoryElementPayload;
    label?: string;
    /** Will run the cell immediately, regardless, unless `forceDisableRunOnInsertion` is also set. */
    runAfterInsertion?: boolean;
    /**
     * By default, some cells run automatically when created and others don't.
     * If this is set, the cell won't run when created regardless of other factors.
     * Overrides `runAfterInsertion` if both are true.
     */
    forceDisableRunOnInsertion?: boolean;
    parentComponentImportCellId?: ComponentImportCellId;
    parentCellId?: CellId;
    staticCellIdInSourceComponent?: StaticCellId;
    componentImportCellId?: ComponentImportCellId | null;
    parentBlockCellId?: BlockCellId;
    blockCellId?: BlockCellId | null;
    excludeFromHistory?: boolean;
    latestMagicEventId?: MagicEventId | null;
    isTemplate?: boolean;
    origin?: CreateOriginMetadata;
  }) => ({
    type: CREATE_CELL_TYPE,
    payload: {
      ...args,
    },
  }),
});

export type CREATE_CELL = ExtractAtomicOperationFromDefinition<
  typeof CREATE_CELL
>;
